import {
  LandingContainer,
  LandingH1,
  LightTitle,
  LandingP,
} from "./LandingElements";

const Landing = () => {
  return (
    <LandingContainer id="landing">
      <LandingH1>
        stoic<LightTitle>space</LightTitle>
      </LandingH1>
      <LandingP>Built for the modern stoic.</LandingP>
    </LandingContainer>
  );
};

export default Landing;

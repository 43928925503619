import styled from "styled-components";

export const LandingContainer = styled.section`
  z-index: 3;
  position: relative;
  padding: 40px 10px 0 0;
  margin: 140px auto 30px auto;
  display: flex;
  flex-direction: column;
  align-items: left;

  @media screen and (max-width: 768px) {
    margin-top: 100px;
    margin-bottom: 60px;
  }
`;

export const LandingH1 = styled.h1`
  font-size: 2.75rem;
  font-weight: 700;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    margin-top: -20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 2.25rem;
  }
`;

export const LightTitle = styled.span`
  font-weight: 100;
`;

export const LandingP = styled.p`
  margin-top: 0;
  font-size: 1.125rem;
  font-weight: 100;
`;
